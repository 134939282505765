.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: radial-gradient(circle, #225c77 10%, #2e4482 50%, #16203a 100%);
  color: #ffd700;
  min-height: 20vh;
  width: 100%;
  text-shadow: 2px 2px 4px #000000;
  padding-bottom: 10px;
}

.title {
  margin: 0;
  text-align: center;
  font-size: 1.2rem;
  color: #ffd700;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.6);
}

.subtitle {
  font-size: 1.2em;
  margin-top: 20px;
}

.paragraph {
  font-size: 0.9rem;
  text-align: center;
  line-height: 1.5;
  max-width: 80%;
  margin: 10px auto;
  color: #e6e6e6;
  text-shadow: 1px 1px 3px #000000;
}

@media (min-width: 768px) {
  .title {
    font-size: 1.8rem;
    letter-spacing: 0.05em;
  }

  .paragraph {
    font-size: 1rem;
    max-width: 600px;
  }
}
