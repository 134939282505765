.container {
  display: flex;
  max-width: 95vw;
  margin: 20px auto;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 10px;
}

.logoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.textContainer h2 {
  font-size: 1.5rem;
  margin: 10px 0;
}

.textContainer p {
  font-size: 1rem;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .container {
    max-width: 95vw;
    flex-direction: row;
    justify-content: space-around;
    text-align: left;
  }

  .logoContainer,
  .textContainer {
    flex: 1;
    margin: 0 10px;
  }

  .textContainer {
    margin: 0 20px;
  }
}

@media (min-width: 1050px) {
  .container {
    max-width: 80vw;
  }
}

@media (min-width: 1250px) {
  .container {
    max-width: 60vw;
  }
}

@media (min-width: 2250px) {
  .container {
    max-width: 35vw;
  }
}
