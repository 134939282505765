/* BlockchainExpertiseSection.module.css */
.blockchainExpertiseSection {
  background-color: #e8f4f8; /* Couleur de fond */
  padding: 40px;
  text-align: center;
}

.title {
  color: #2a5885; /* Couleur du titre */
  margin-bottom: 20px;
}

.description {
  color: #333333; /* Couleur du texte du paragraphe */
  line-height: 1.6;
}

/* Ajouter d'autres styles si nécessaire */
