/* ShowcaseSection.module.css */
.showcase {
  background-color: #f5f5f5;
  padding: 20px;
  text-align: center;
}

.title {
  color: #333;
  margin-bottom: 10px;
}

.description {
  color: #666;
  line-height: 1.6;
}
