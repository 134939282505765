.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
  max-width: 90%;
  margin: 0 auto;
}

.textContainer {
  max-width: 800px;
  margin: 20px auto;
}

.textContainer h2,
.textContainer h3 {
  font-size: 1.8rem;
  margin-bottom: 15px;
}

.textContainer p {
  font-size: 1rem;
  line-height: 1.6;
}

.logoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.icon {
  width: 60px;
  height: 60px;
}

.textContainer ul {
  list-style-type: disc;
  padding-left: 20px;
  text-align: left;
  margin: 10px 0;
}

.textContainer ul li {
  margin: 10px 0;
}

@media (min-width: 768px) {
  .container {
    max-width: 80%;
  }

  .textContainer {
    margin: 40px 0;
  }

  .textContainer h2,
  .textContainer h3 {
    font-size: 2rem;
  }

  .textContainer p {
    font-size: 1.1rem;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 70%;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 60%;
  }
}
