/* ContactForm.module.css */

.formContainer {
  background-color: #6791e1;
  border-radius: 25px;
  border: solid 2px #2a60a5;
  padding: 20px;
  max-width: 500px;
  margin: 20px auto;
  color: white;
}

.form {
  display: flex;
  flex-direction: column;
}

.label {
  margin: 5px 0;
}

.input {
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.inputError {
  border-color: red;
}

.textArea {
  width: calc(100% - 20px);
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: none;
  overflow: auto;
  position: relative;
  box-sizing: border-box; /* Ensure padding is included in width calculation */
}

.textAreaError {
  border-color: red;
}

.submitButton {
  padding: 10px;
  background-color: #222;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submitButton:hover:not(:disabled) {
  background-color: #444;
}

.submitButton:disabled {
  background-color: #888;
  cursor: not-allowed;
}

.row {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

@media (min-width: 601px) {
  .row {
    flex-direction: row;
    justify-content: space-between;
  }
}

.halfInput {
  flex: 1;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box; /* Include padding and border in width */
}

.charCounter {
  position: absolute;
  bottom: 5px;
  right: 10px;
  font-size: 0.8em;
  color: #aaa;
}

/* Accessibility and performance improvements */
.input,
.textArea,
.halfInput {
  font-family: inherit;
  font-size: 1rem;
  outline: none;
}

.input:focus,
.textArea:focus,
.halfInput:focus {
  border-color: #2a60a5;
  box-shadow: 0 0 5px rgba(42, 96, 165, 0.5);
}
