/* CoreValues.module.css */
.coreValuesSection {
  position: relative;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px;

  @media (min-width: 768px) {
    padding: 20px;
  }
}

.cardContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;

  @media (min-width: 768px) {
    justify-content: space-evenly;
  }
}

.title {
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}
