/* src/styles/toc.module.css */
.tocContainer {
  margin: 20px;
}

.tocItem {
  display: block;
  margin: 10px 0;
  text-decoration: none;
  color: #0070f3;
}

.tocItem:hover {
  text-decoration: underline;
}
