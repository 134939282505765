/* InfoBoxes.module.css */
.boxContainer {
  display: flex;
  flex-direction: column;
  width: 80vw;
  gap: 30px;
  margin: 20px auto;
}

@media (min-width: 768px) {
  .boxContainer {
    width: 70vw;
    flex-direction: row;
  }
}

@media (min-width: 1250px) {
  .boxContainer {
    width: 50vw;
    flex-direction: row;
  }
}

@media (min-width: 2250px) {
  .boxContainer {
    width: 40vw;
    gap: 100px;
    flex-direction: row;
  }
}

.infoBox {
  background-color: #333;
  color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  flex-basis: 95%;
  min-height: 200px;
}

@media (min-width: 768px) {
  .infoBox {
    flex-basis: 48%;
  }
}

.title {
  margin-bottom: 10px;
  color: #86cde9;
}

.content {
  /* Additional content styles here if necessary */
}

.svg {
  height: 50px;
  width: 50px;
  margin-bottom: 10px;
  fill: #ffd700;
}

.ul {
  list-style: none;
  padding: 0;
  text-align: center;
}

.li {
  display: inline-block;
  width: 100%;
}
