/* Web3Bg.module.css */
.web3Background {
  position: relative;
  width: 100%;
  padding-bottom: 66.67%; /* Aspect ratio based on image dimensions */
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the container without distortion */
}
