.proSection {
  text-align: center;
  padding: 40px 20px;
}

.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  gap: 20px;
  margin-top: 20px;
}

.card {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  width: 100%;
  max-width: 300px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card h3 {
  margin-top: 10px;
  font-size: 1.2em;
}

.card p {
  margin-top: 10px;
  font-size: 0.9em;
  line-height: 1.4;
}

.icon {
  background-size: contain;
  height: 50px;
  width: 50px;
  margin: 0 auto 10px;
}

.image {
  width: 100%;
  height: auto;
  border-radius: 8px;
}
