/* TeamSection.module.css */
.teamSection {
  text-align: center;
  margin: 50px 0;

  @media (min-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
  }
}

.title {
  font-size: 2em;
  margin-bottom: 20px;
}

.text {
  margin: 0 0 0 auto;
  max-width: 600px;

  @media (min-width: 768px) {
    max-width: 30%;
  }
}

.imageContainer {
  width: 50vw;
  margin: 0 auto;

  @media (min-width: 768px) {
    flex: 0 0 45vw;
  }
}
