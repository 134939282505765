.card {
  border-radius: 15px;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease;
  width: 100%;
  max-width: 350px;
  background: var(--cardBg);
  color: var(--cardTxt);
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
}

@media (min-width: 1250px) {
  .card {
    max-width: 250px;
  }
}

.content {
  padding: 1.5em;
  text-align: center;
}

.title {
  margin-bottom: 10px;
}

.button {
  position: relative;
  border: none;
  display: flex;
  justify-content: center;
  box-shadow: none;
  min-width: 100px;
  height: 40px;
  line-height: 42px;
  perspective: 230px;
  cursor: pointer;
}

.button span {
  background: linear-gradient(0deg, rgba(0, 172, 238, 1) 0%, rgba(2, 126, 251, 1) 100%);
  display: block;
  position: absolute;
  width: 130px;
  height: 40px;
  box-shadow:
    inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
    7px 7px 20px 0px rgba(0, 0, 0, 0.1),
    4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin: 0;
  text-align: center;
  box-sizing: border-box;
  transition: all 0.3s;
}

.button span:nth-of-type(1) {
  box-shadow:
    -7px -7px 20px 0px #fff9,
    -4px -4px 5px 0px #fff9,
    7px 7px 20px 0px #0002,
    4px 4px 5px 0px #0001;
  transform: rotateX(90deg);
  transform-origin: 50% 50% -20px;
}

.button span:nth-of-type(2) {
  transform: rotateX(0deg);
  transform-origin: 50% 50% -20px;
}

.button:hover span:nth-of-type(1) {
  box-shadow:
    inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
    7px 7px 20px 0px rgba(0, 0, 0, 0.1),
    4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  transform: rotateX(0deg);
}

.button:hover span:nth-of-type(2) {
  box-shadow:
    inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
    7px 7px 20px 0px rgba(0, 0, 0, 0.1),
    4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  color: transparent;
  transform: rotateX(-90deg);
}
