/* CoreServices.module.css */
.coreServicesSection {
  position: relative;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  max-width: 1200px;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .coreServicesSection {
    padding: 20px;
  }
  .cardContainer {
    justify-content: space-evenly;
    margin: 0 auto;
  }
}

.cardContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.title {
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.infoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
}

.infoContainer p {
  margin: 10px 0;
}

.subtitle {
  text-align: center;
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.cardContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.cardContainer img {
  width: 256px;
  height: auto;
}
