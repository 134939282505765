/* BlockchainServicesSection.module.css */
.blockchainServicesSection {
  background-color: #f8f8f8; /* Couleur de fond */
  padding: 40px;
  text-align: center;
}

.title {
  color: #004d99; /* Couleur du titre */
  margin-bottom: 20px;
}

.description {
  color: #333; /* Couleur du texte du paragraphe */
  line-height: 1.6;
}

/* Ajouter des styles supplémentaires si nécessaire */
