.cardContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 40px 0;
  box-sizing: border-box;
  font-family: consolas;
}
.cardBox {
  position: relative;
  width: 320px;
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px 30px;
  transition: 0.5s;
}

/* Media query pour les tablettes */
@media (min-width: 768px) {
  .cardBox {
    height: 470px;
    margin: 40px 40px;
  }
}
/* Media query pour les grands écrans de bureau */
@media (min-width: 1024px) {
  .cardBox {
    height: 550px;
    margin: 40px 50px;
  }
}

@media (min-width: 1750px) {
  .cardBox {
    height: 600px;
    margin: 40px 90px;
  }
}

@media (min-width: 2124px) {
  .cardBox {
    height: 640px;
    margin: 40px 120px;
  }
}

.cardBox::before {
  content: ' ';
  position: absolute;
  top: 0;
  left: 50px;
  width: 50%;
  height: 100%;
  text-decoration: none;
  background: #fff;
  border-radius: 8px;
  transform: skewX(15deg);
  transition: 0.5s;
}
.cardBox::after {
  content: '';
  position: absolute;
  top: 0;
  left: 50;
  width: 50%;
  height: 100%;
  background: #fff;
  border-radius: 8px;
  transform: skewX(15deg);
  transition: 0.5s;
  filter: blur(30px);
}
.cardBox:hover::before,
.cardBox:hover::after {
  transform: skewX(0deg);
  left: 20px;
  width: calc(100% - 90px);
}
.cardBox:nth-child(1):before,
.cardBox:nth-child(1):after {
  background: linear-gradient(315deg, #ffbc00, #ff0058);
}
.cardBox:nth-child(2):before,
.cardBox:nth-child(2):after {
  background: linear-gradient(315deg, #03a9f4, #ff0058);
}
.cardBox:nth-child(3):before,
.cardBox:nth-child(3):after {
  background: linear-gradient(315deg, #4dff03, #00d0ff);
}
.cardBox span {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  pointer-events: none;
  overflow: hidden;
}
.cardBox span::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  transition: 0.1s;
  animation: animate 2s ease-in-out infinite;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
}
.cardBox:hover span::before {
  top: -50px;
  left: 50px;
  width: 100px;
  height: 100px;
  opacity: 1;
}
.cardBox span::after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  transition: 0.5s;
  animation: animate 2s ease-in-out infinite;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
  animation-delay: -1s;
}
.cardBox:hover span::after {
  bottom: -50px;
  right: 50px;
  width: 100px;
  height: 100px;
  opacity: 1;
}
@keyframes animate {
  0%,
  100% {
    transform: translateY(10px);
  }
  50% {
    transform: translateY(-10px);
  }
}
.cardContent {
  position: relative;
  left: 0;
  padding: 20px 40px;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 1;
  color: #fff;
}
.cardBox:hover .cardContent {
  left: -25px;
  padding: 60px 40px;
}
.cardContent h2 {
  /* font-size: 2em;*/
  color: #fff;
  margin-bottom: 10px;
}
.cardContent p {
  /*font-size: 1.1em;*/
  margin-bottom: 10px;
  line-height: 1.4em;
}
.cardContent a {
  display: inline-block;
  /*font-size: 1.1em;*/
  color: #111;
  background: #fff;
  padding: 10px;
  border-radius: 4px;
  text-decoration: none;
  font-weight: 700;
  margin-top: 5px;
}
.cardContent a:hover {
  background: #ffcf4d;
  border: 1px solid rgba(255, 0, 88, 0.4);
  box-shadow: 0 1px 15px rgba(1, 1, 1, 0.2);
}
