/* FranchiseSection.module.css */
.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}

.title {
  text-align: center;
  margin-bottom: 10px;
}

.subTitle {
  text-align: center;
  margin-bottom: 20px;
}

.choicesContainer {
  display: flex;
  justify-content: center;
  gap: 20px;
  width: 80%;
}

.choice {
  padding: 10px;
  width: 65%;
  text-align: center;
}
