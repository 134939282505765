.container {
  padding: 20px;
}

.title {
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.subtitle {
  text-align: center;
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}

.textSection,
.imageSection {
  padding: 20px;
  max-width: 100%;
}

.textSection p {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 20px;
}

.callButton {
  display: block;
  width: 100%;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  margin-bottom: 20px;
  background: var(--layoutBg);
}

.phoneSize {
  font-size: 2rem;
}

.commitments {
  padding: 20px;
  border-radius: 5px;
  background-color: var(--commitmentBg);
}

.commitments h3 {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.commitments ul {
  list-style-type: none;
  padding: 0;
}

.commitments li {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  .title {
    font-size: 2rem;
  }

  .subtitle {
    font-size: 1.5rem;
  }

  .section {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .textSection,
  .imageSection {
    max-width: 600px;
  }

  .section:nth-of-type(odd) .textSection {
    order: 1;
  }

  .section:nth-of-type(odd) .imageSection {
    order: 2;
  }

  .section:nth-of-type(even) .textSection {
    order: 2;
  }

  .section:nth-of-type(even) .imageSection {
    order: 1;
  }
}
