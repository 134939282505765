/* StorySection.module.css */
.section {
  position: relative;
  display: flex;
  background: linear-gradient(180deg, rgba(21, 11, 49, 0.76) 0%, rgba(13, 5, 29, 0.88) 50%, rgba(10, 3, 18, 1) 100%);
  color: #ffff;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 30vh;
}

.socialIcons {
  display: flex;
  width: 100%;
  max-width: 15rem;
  margin: 25px auto;
  gap: 10px;
  justify-content: center;
}

@media (min-width: 769px) {
  .section {
    min-height: 50vh;
  }
}
