/* OptiValue.module.css */
.secubgBackground {
  position: relative;
  width: 100%; /* Largeur du conteneur */
  padding-bottom: 66.67%; /* Ratio d'aspect calculé (hauteur / largeur * 100) */
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Assure que l'image remplit le conteneur sans déformation */
}
